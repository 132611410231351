import { format, getMonth, getYear, parse, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import { capitalize, isArray } from "lodash";

import { isObject } from "./object";

export function formattedDate(date: string): string {
  return date?.slice(0, 10).split("-", 3).reverse().join("/") ?? "-";
}

export function formatToDate(value: Date) {
  return format(value, "dd/MM/yyyy");
}

export function formatToDateWithoutYear(value: Date) {
  return format(value, "dd/MM");
}

export function formatToDatetime(value: Date) {
  let dateToFormat = value;
  if (typeof value === "string") {
    dateToFormat = new Date(dateToFormat);
  }

  return format(dateToFormat, "dd/MM/yyyy HH:mm");
}

export function formatToTimeDate(value: Date) {
  return format(value, "HH:mm - dd/MM");
}

export function formatToHour(value: Date) {
  return format(value, "HH:mm");
}

function executeFunctionOnAllObjectLayers(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (value: any) => any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: Record<string, any>,
  fields: string[]
) {
  if (!object) {
    return;
  }

  Object.entries(object).forEach(([key, value]) => {
    if (value !== null && (isArray(value) || isObject(value))) {
      if (isArray(value)) {
        value.forEach((element) => {
          if (typeof element === "object") {
            executeFunctionOnAllObjectLayers(callback, element, fields);
          }
        });
      } else {
        executeFunctionOnAllObjectLayers(callback, value, fields);
      }
    } else {
      if (fields.includes(key)) {
        object[key] = callback(value);
      }
    }
  });

  return object;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertDateStringToISOInObject(object: Record<string, any>, fields: string[]) {
  return executeFunctionOnAllObjectLayers(
    (value: string) => {
      if (value === "") {
        return null;
      } else {
        return parse(value, "dd/MM/yyyy", new Date());
      }
    },
    object,
    fields
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertISOToDateInObject(object: Record<string, any>, fields: string[]) {
  return executeFunctionOnAllObjectLayers(
    (value: string | null) => {
      if (value !== null) {
        return parseISO(value);
      }
    },
    object,
    fields
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertISOToDateStringInObject(object: Record<string, any>, fields: string[]) {
  return executeFunctionOnAllObjectLayers(
    (value: string | null) => {
      if (value === null) {
        return "";
      } else {
        return format(parseISO(value), "dd/MM/yyyy");
      }
    },
    object,
    fields
  );
}

export function getWeekDayLabelByNumber(weekDay: number) {
  switch (weekDay) {
    case 0:
      return "DOM";
    case 1:
      return "SEG";
    case 2:
      return "TER";
    case 3:
      return "QUA";
    case 4:
      return "QUI";
    case 5:
      return "SEX";
    case 6:
      return "SAB";
    default:
      break;
  }
}

export function getCurrentMonthAndYear() {
  const now = new Date();
  const currentMonth = getMonth(now);
  const currentYear = getYear(now);

  return { currentMonth, currentYear };
}

export function getFirstAndLastDateOfMonth(monthIndexValue: number, yearValue: number) {
  const firstDayOfMonth = new Date(yearValue, monthIndexValue, 1, 0);
  const lastDayOfMonth = new Date(yearValue, monthIndexValue + 1, 0, 23, 59, 59);

  return { firstDayOfMonth, lastDayOfMonth };
}

export function nominativeMonthYear(date: Date): string {
  return capitalize(format(date, "LLLL yyyy", { locale: ptBR }));
}
